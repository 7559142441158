<template>
  <div>
    <setup-table
      :title="`Vehicle`"
      :items="items"
      :headers="headers"
      :loading="loading"
      :clearItem="!showForm || !editedItem.id"
      @createItem="createItem()"
      @editItem="editItem"
      @changeStatus="changeStatus"
      @deleteItems="deleteItems"
    >
      <template #itemForm v-if="showForm">
        <vehicle-form
          ref="vehicleForm"
          :value="editedItem"
          :vehicleTypes="filterVehicleTypes"
          :vehicleLocations="vehicleLocations"
          :drivers="drivers"
          @submit="saveItem"
          @closeForm="closeForm"
        ></vehicle-form>
      </template>

      <template #[`item.synced`]="{ item }">
        <v-icon small v-if="item.synced">mdi-sync</v-icon>
      </template>
      <template #[`item.type`]="{ item }">
        <p class="mb-0">{{ vehicleTypesById[item.type] ? vehicleTypesById[item.type].name : '' }}</p>
      </template>
      <template #[`item.location`]="{ item }">
        <p class="mb-0">{{ getVehicleLocation(item.id) }}</p>
      </template>
      <template #[`item.lift`]="{ item }">
        <v-icon small>{{ item.wheelchairLift ? 'mdi-check' : 'mdi-close' }}</v-icon>
      </template>
      <template #[`item.status`]="{ item }">
        <p class="mb-0">{{ item.active ? 'Active' : 'Inactive' }}</p>
      </template>
    </setup-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_VEHICLES, SAVE_VEHICLE, DELETE_VEHICLES } from '@/store/modules/Vehicle/actions';
import { GET_VEHICLE_TYPES } from '@/store/modules/VehicleType/actions';
import SetupTable from './SetupTable.vue';
import VehicleForm from './VehicleForm.vue';
import { getVehicleLocation } from '@/util';
import VEHICLE_TYPES from '@/shared/types';

export default {
  name: 'VehicleSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, VehicleForm },
  data() {
    return {
      getVehicleLocation,
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      VEHICLE_TYPES,
    };
  },
  computed: {
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('vehicleType', ['vehicleTypes', 'vehicleTypesById']),
    ...mapGetters('location', ['vehicleLocations', 'locationsById']),
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('stop', ['stopsById']),
    ...mapGetters('additionalTransportation', ['additionalTransportations']),
    headers() {
      const h = [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Location', value: 'location' },
        { text: 'Lift', value: 'lift' },
        { text: 'Status', value: 'status' },
      ];
      if (this.isRoutingClient) h.unshift({ text: '', value: 'synced' });
      return h;
    },
    filterVehicleTypes() {
      const contractors = this.additionalTransportations.filter((e) => e.type == 'contractor');
      const filterTypeIds = new Set([1, 2, 3]);
      if (contractors.length) filterTypeIds.delete(2);
      return this.vehicleTypes.filter((e) => !filterTypeIds.has(e.vehicleType));
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('vehicle', [GET_VEHICLES, SAVE_VEHICLE, DELETE_VEHICLES]),
    ...mapActions('vehicleType', [GET_VEHICLE_TYPES]),
    async fetchItems() {
      await this.getVehicles({ forTable: true });
      await this.getVehicleTypes();
      this.items = this.vehicles;
      this.loading = false;
    },
    createItem() {
      this.editedItem = { id: 0, active: 1, name: '', vehicleOrder: [], ttOnly: this.isRoutingClient ? true : false };
      this.showForm = true;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveVehicle(item);
        if (r && r.id) {
          this.$myalert.success('Vehicle saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async changeStatus(items, status) {
      for (let vehicle of items)
        try {
          await this.saveVehicle({ ...vehicle, active: status });
        } catch (error) {
          this.$myalert.error(error.message);
        }
      await this.fetchItems();
      this.$myalert.success('Vehicles updated');
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Vehicles' : 'this Vehicle'
        }? This operation cannot be undone.`
      );
      if (yes) {
        try {
          const r = await this.deleteVehicles(items.map((e) => e.id));
          if (r.done) {
            this.$myalert.success(`${items.length > 1 ? 'Vehicles' : 'Vehicle'} deleted`);
            this.showForm = false;
            this.fetchItems();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
