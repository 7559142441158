<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-form :readonly="value.id > 0 && !canEditSetupTables">
        <v-container>
          <v-alert v-if="value.synced" outlined type="info" text>
            This Vehicle is synced with EZRouting. Some fields will not be able to be modified here.
          </v-alert>
          <v-alert v-else-if="!value.id && isRoutingClient" outlined type="info" text>
            This Vehicle will only be avilable in EZActivityTrips. If you would like this Vehicle to be available in
            EZRouting, please add it there first and it will be synced with EZActivityTrips.
          </v-alert>
          <v-row dense>
            <v-col cols="12" md="2">
              <v-checkbox
                :value="value.active"
                :readonly="value.synced"
                v-model="$v.form.active.$model"
                label="Active"
                required
                @change="$v.form.active.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                :value="value.name"
                v-model.trim="$v.form.name.$model"
                label="Name"
                :readonly="value.synced"
                required
                :error-messages="handleErrors($v.form.name)"
                @blur="$v.form.name.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Type"
                :value="value.type"
                :readonly="value.synced"
                v-model="$v.form.type.$model"
                :items="vehicleTypes"
                item-text="name"
                item-value="id"
                outlined
                class="mb-0"
                :error-messages="handleErrors($v.form.type)"
                :menu-props="{ bottom: true, offsetY: true }"
                @blur="$v.form.type.$touch()"
                required
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                label="Location"
                :value="value.depotId"
                v-model="$v.form.depotId.$model"
                :items="vehicleLocations"
                item-text="name"
                item-value="depotId"
                outlined
                required
                class="mb-0"
                :error-messages="handleErrors($v.form.depotId)"
                :menu-props="{ bottom: true, offsetY: true }"
                @blur="$v.form.depotId.$touch()"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Driver"
                :value="value.driverId"
                v-model="$v.form.driverId.$model"
                :items="drivers"
                :item-text="getDriverName"
                item-value="id"
                outlined
                clearable
                class="mb-0"
                :error-messages="handleErrors($v.form.driverId)"
                :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select>
              <v-alert v-if="upcomingAssignments?.vehicleId" class="mb-0" outlined type="warning" text>
                This vehicle has an upcoming assignment with Trip Request #{{ upcomingAssignments.tripRequestId }} on
                {{ convertDateFormat(upcomingAssignments.vehPickupDate + ' ' + upcomingAssignments.vehPickupTime) }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.capacityElem"
                v-model.trim="$v.form.capacityElem.$model"
                label="Capacity (Elementary)"
                type="number"
                min="0"
                :readonly="value.synced"
                :error-messages="handleErrors($v.form.capacityElem)"
                @blur="$v.form.capacityElem.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.capacityMid"
                v-model.trim="$v.form.capacityMid.$model"
                label="Capacity (Middle)"
                type="number"
                min="0"
                :error-messages="handleErrors($v.form.capacityMid)"
                @blur="$v.form.capacityMid.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.capacityHigh"
                v-model.trim="$v.form.capacityHigh.$model"
                label="Capacity (High)"
                type="number"
                min="0"
                :error-messages="handleErrors($v.form.capacityHigh)"
                @blur="$v.form.capacityHigh.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.plateNum"
                v-model.trim="$v.form.plateNum.$model"
                :readonly="value.synced"
                label="Plate Number"
                :error-messages="handleErrors($v.form.plateNum)"
                @blur="$v.form.plateNum.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.vin"
                v-model.trim="$v.form.vin.$model"
                :readonly="value.synced"
                label="VIN"
                :error-messages="handleErrors($v.form.vin)"
                @blur="$v.form.vin.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.modelYear"
                v-model.trim="$v.form.modelYear.$model"
                :readonly="value.synced"
                label="Model Year"
                :error-messages="handleErrors($v.form.modelYear)"
                @blur="$v.form.modelYear.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.make"
                v-model.trim="$v.form.make.$model"
                :readonly="value.synced"
                label="Make"
                :error-messages="handleErrors($v.form.make)"
                @blur="$v.form.make.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-checkbox
                :value="value.wheelchairLift"
                v-model="$v.form.wheelchairLift.$model"
                label="Wheelchair Lift"
                required
                @change="$v.form.wheelchairLift.$touch()"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                :value="value.camera"
                v-model="$v.form.camera.$model"
                label="Camera"
                required
                @change="$v.form.camera.$touch()"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :value="value.wheelchair"
                v-model.trim="$v.form.wheelchair.$model"
                label="# Wheelchair Slots"
                type="number"
                min="0"
                :readonly="value.synced"
                :error-messages="handleErrors($v.form.wheelchair)"
                @blur="$v.form.wheelchair.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :value="value.numSafetyVest"
                v-model.trim="$v.form.numSafetyVest.$model"
                label="# Safety Vests"
                type="number"
                min="0"
                :error-messages="handleErrors($v.form.numSafetyVest)"
                @blur="$v.form.numSafetyVest.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :value="value.numFoldDownSeat"
                v-model.trim="$v.form.numFoldDownSeat.$model"
                label="# Fold Down Seats"
                type="number"
                min="0"
                :error-messages="handleErrors($v.form.numFoldDownSeat)"
                @blur="$v.form.numFoldDownSeat.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.engineType"
                v-model.trim="$v.form.engineType.$model"
                label="Engine Type"
                :error-messages="handleErrors($v.form.engineType)"
                @blur="$v.form.engineType.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.bodyType"
                v-model.trim="$v.form.bodyType.$model"
                label="Body Type"
                :error-messages="handleErrors($v.form.bodyType)"
                @blur="$v.form.bodyType.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :value="value.gpsDeviceId"
                v-model.trim="$v.form.gpsDeviceId.$model"
                label="GPS Device"
                disabled
                :error-messages="handleErrors($v.form.gpsDeviceId)"
                @blur="$v.form.gpsDeviceId.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-radio-group row v-model="$v.form.travelConditions.$model">
                <template v-slot:label>
                  <strong>Travel Conditions</strong>
                </template>
                <v-radio :value="1">
                  <template v-slot:label>
                    <div>In County Only</div>
                  </template>
                </v-radio>
                <v-radio :value="2">
                  <template v-slot:label>
                    <div>Unlimited Mileage</div>
                  </template>
                </v-radio>
                <v-radio :value="3">
                  <template v-slot:label>
                    <div>50 Mile Radius</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-textarea
                :value="value.description"
                v-model.trim="$v.form.description.$model"
                label="Description"
                outlined
                rows="1"
                auto-grow
                :error-messages="handleErrors($v.form.description)"
                @blur="$v.form.description.$touch()"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
// import UserSelect from '@/components/UserSelect.vue';
import { handleErrors } from '@/util';

const newVehicle = {
  name: '',
  type: '',
  description: '',
  active: '',
  driverId: '',
  depotId: '',
  capacity: '',
  capacityElem: '',
  capacityMid: '',
  capacityHigh: '',
  wheelchair: '',
  numSafetyVest: '',
  numFoldDownSeat: '',
  gpsDeviceId: '',
  color: '',
  plateNum: '',
  modelYear: '',
  make: '',
  vin: '',
  engineType: '',
  bodyType: '',
  wheelchairLift: '',
  camera: '',
  travelConditions: 0,
};

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    handleErrors,
    panel: [0],
    id: null,
    form: newVehicle,
    search: null,
    saved: false,
    upcoming: {},
  }),
  validations: {
    form: {
      name: { required },
      type: { required },
      description: {},
      active: {},
      driverId: {},
      depotId: { required },
      capacity: {},
      capacityElem: {},
      capacityMid: {},
      capacityHigh: {},
      wheelchair: {},
      numSafetyVest: {},
      numFoldDownSeat: {},
      gpsDeviceId: {},
      color: {},
      plateNum: {},
      modelYear: {},
      make: {},
      vin: {},
      engineType: {},
      bodyType: {},
      wheelchairLift: {},
      camera: {},
      travelConditions: {},
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('assignment', ['assignments']),
    upcomingAssignments() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let upcoming = [];

      upcoming = this.assignments.find((item) => {
        const pickupDate = new Date(item.vehPickupDate);
        return item.vehicleId === this.id && pickupDate > today;
      });

      return upcoming;
    },
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newVehicle;
        if (this.$refs.aform) this.$refs.aform.clear();
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    convertDateFormat(dateTimeStr) {
      const date = new Date(dateTimeStr);

      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let year = date.getFullYear();

      let hours = date.getHours();
      let minutes = date.getMinutes().toString().padStart(2, '0');

      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;

      return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
    },
    // handleUserSelect(users, field) {
    //   this.$v.form[field].$model = users;
    //   this.$v.form.$touch();
    // },
    getDriverName(driver) {
      return driver.firstName + ' ' + driver.lastName;
    },
    compareValues() {
      if (!this.id && this.$v.form.$model.name) return true;
      if (!this.id) return false;
      if (
        this.$v.form.name.$model != this.value.name ||
        this.$v.form.type.$model != this.value.type ||
        this.$v.form.description.$model != this.value.description ||
        this.$v.form.active.$model != this.value.active ||
        this.$v.form.driverId.$model != this.value.driverId ||
        this.$v.form.depotId.$model != this.value.depotId ||
        this.$v.form.capacity.$model != this.value.capacity ||
        this.$v.form.capacityElem.$model != this.value.capacityElem ||
        this.$v.form.capacityMid.$model != this.value.capacityMid ||
        this.$v.form.capacityHigh.$model != this.value.capacityHigh ||
        this.$v.form.wheelchair.$model != this.value.wheelchair ||
        this.$v.form.numSafetyVest.$model != this.value.numSafetyVest ||
        this.$v.form.numFoldDownSeat.$model != this.value.numFoldDownSeat ||
        this.$v.form.gpsDeviceId.$model != this.value.gpsDeviceId ||
        this.$v.form.color.$model != this.value.color ||
        this.$v.form.plateNum.$model != this.value.plateNum ||
        this.$v.form.modelYear.$model != this.value.modelYear ||
        this.$v.form.make.$model != this.value.make ||
        this.$v.form.vin.$model != this.value.vin ||
        this.$v.form.engineType.$model != this.value.engineType ||
        this.$v.form.bodyType.$model != this.value.bodyType ||
        this.$v.form.wheelchairLift.$model != this.value.wheelchairLift ||
        this.$v.form.camera.$model != this.value.camera
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newVehicle,
    },
    vehicleTypes: {
      type: Array,
      default: () => [],
    },
    vehicleLocations: {
      type: Array,
      default: () => [],
    },
    drivers: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.form = { ...newVehicle, ...value };
          this.address = value.address;
        }
      },
    },
  },
};
</script>
